import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../features/auth/authSlice';
import { refreshToken } from './refreshTokenApiSlice';  

const baseQuery = fetchBaseQuery({
    baseUrl: 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod',
    //    baseUrl: 'https://localhost:61148',
    credentials: 'same-origin',
    prepareHeaders: (headers, { getState }: any) => {
        const token: any = getState().auth.token;
        if (token) {
            console.log("Agregando token", token)
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
        console.log(`El endpoint que falló es: ${args.url}`);
        const storedToken = JSON.parse(localStorage.getItem('sessionA')!);
        if (storedToken?.refresh) {

            //console.log("Entrando al if stordetoekn")
            try {
                
                console.log("Existe refreshtoken") // testear cuando refreshtoken caduca
                const refreshedData = await refreshToken(storedToken.refresh, api);
                console.log("*****************",refreshedData)
                
                localStorage.setItem(
                    'sessionA',
                    JSON.stringify({
                        id: refreshedData.IdToken,
                        refresh: refreshedData.RefreshToken,
                        user_id: storedToken.user_id,
                    })
                );

             
                const newArgs = {
                    ...args,
                    headers: {
                        ...args.headers,
                        authorization: `Bearer ${refreshedData.IdToken}`,
                    },
                };

                return await baseQuery(newArgs, api, extraOptions);
            } catch (error) {
                console.error('Error al renovar el token:', error);
                console.log("401 = > Error al renovar token con refresh_token, deslogueando") // refresh_token caducado
                api.dispatch(logOut({}));
                window.location.href = '/sign-out'
                //localStorage.removeItem('sessionA');
                //
                return { error: { status: 401, message: 'Token expirado, no se pudo renovar' } };
            }
        } else { // caso en que no exista un refresh_token
            console.log('No se renovó el token, deslogeando...');
            //localStorage.removeItem('sessionA');
            api.dispatch(logOut({}));
            console.log("401 => tokenrefresh inexistente o inválido, deslogueando.")
            window.location.href = '/sign-out'
        }
    }

    if (result?.error?.status === 403) {
        const refreshResult = await baseQuery('/refresh', api, extraOptions);
        if (refreshResult?.data) {
            const user = api.getState().auth.user;
            api.dispatch(setCredentials({ ...refreshResult.data, user }));
            result = await baseQuery(args, api, extraOptions);
        } else {
            //api.dispatch(logOut({}));
            console.log("error 403")
            //window.location.href = '/sign-out3'
        }
    }

    return result;
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
});
