import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./api/apiSlice"
import authReducer, { setCredentials } from './features/auth/authSlice'
import languageReducer from './features/language/LanguageSlice'
import dimensionsReducer from './features/dimensions/dimensionsSlice'
import browserdataReducer from './features/browserdata/browserdataSlice'
import userReducer from './features/user/userSlice'
import startupsReducer from './features/startups/startupsSlice'
import alertsReducer from './features/alerts/alertsSlice'
import meetingsReducer from './features/meetings/meetingsSlice'
import recomReducer from './features/recom/recomSlice'
import notificationsReducer from "./features/notifications/notificationsSlice"

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        language: languageReducer,
        dimensions: dimensionsReducer,
        browserdata: browserdataReducer,
        user: userReducer,
        startups: startupsReducer,
        alerts: alertsReducer,
        meetings: meetingsReducer,
        recom: recomReducer,
        notifications: notificationsReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: false // cambiar a false en produccion
})
console.log("Valor en localStorage - store", localStorage.getItem('sessionA'));

 
const storedSession = localStorage.getItem('sessionA')
if (storedSession) {
   
  const { id, refresh, user_id } = JSON.parse(storedSession)
  if (id) {
    store.dispatch(setCredentials({ id, refresh, user_id }))
  }
}

export default store
