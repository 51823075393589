import { selectCurrentDictonary } from '../../../src/redux/features/language/LanguageSlice';
import React, { useEffect, useState } from 'react';
import { connect, Room as VideoRoom } from 'twilio-video';
import io, { Socket } from 'socket.io-client';
import Room from './Room';
import "./twillio.scss"
import { useNavigate } from 'react-router-dom';
import Timer from '../Timer/Timer';
import { useSelector } from 'react-redux';
export interface MainRoom {
    _id: string;
    name: string;
    breakouts: BreakoutRoom[]
}

export interface BreakoutRoom {
    _id: string;
    name: string;
}

const VideoCall2 = ({
    roomName,
    userId,
    UserRequestedEndTime,
    UserRequestedStartTime,
    UserRequestedProfilePicture,
    UserRequestProfilePicture,
    UserRequestName,
    UserRequestLastName,
    UserRequestedName,
    UserRequestedLastName,
    UserRequestId,
    UserRequestedId,
    data_meeting

}: any) => {
    // State variables
    const navigate = useNavigate();
    const [identity, setIdentity] = useState('userid');
    const [room, setRoom] = useState<VideoRoom>();
    const [roomList, setRoomList] = useState<MainRoom[]>([]);
    const [showControls, setShowControls] = useState(false);
    const [newRoomName, setNewRoomName] = useState('');
    //const [RoomName, setRoomName] = useState('');
    const [ActualUserId, setActualUserId] = useState(0);
    const [parentSid, setParentSid] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);

    const [activeAudio, setActiveAudio] = useState(true);
    const [activeVideo, setActiveVideo] = useState(true);
    const [extraTime, setExtraTime] = useState(false);

    const textVideollamada = useSelector(selectCurrentDictonary).videollamada
    useEffect(() => {
        // console.log("VideoCall2 UserId", userId)
        // console.log("VideoCall2 RoomName", roomName)
        if (userId > 0 && roomName.length > 0) {
            handleCreateAndJoinMeeting(userId, roomName)
        } else {

            navigate('/meetings');
        }
    }, [userId, roomName]);



    // Create a new main room
    const createRoom = async (userId: number, roomName: string) => {

        const token = JSON.parse(localStorage.getItem('sessionA')!)?.id || '';

        try {
            //let url_create = 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod/api/Meetings/AddTwillioRoom/' + userId + '/' + roomName
            let url_create = 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod/api/Meetings/AddTwillioRoom/' + userId + '/' + roomName
            
            const response = await fetch(url_create, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            await response.json();


        } catch (err) {
            console.log(err);
        }
    };


    const connectToRoom = async (accessToken: string) => {

        try {
            const videoRoom = await connect(accessToken, {
                audio: true,
                video: { width: 640, height: 480 }
            });
            return videoRoom;

        } catch (error) {

            const videoRoom = await connect(accessToken, {
                audio: true,
                //video: { width: 640, height: 480 }
            });
            return videoRoom;
        }
    }
    // Join a video room
    const joinRoom = async (userId: number, roomSid: string, breakout: boolean = false) => {

        const token = JSON.parse(localStorage.getItem('sessionA')!)?.id || '';

        try {
            // If you're already in another video room, disconnect from that room first
            if (room) {
                await room.disconnect();
            }

            //let url_join = 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod/api/Meetings/GetTwiliioAccessToken/' + userId + '/' + roomSid;
            let url_join = 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod/api/Meetings/GetTwiliioAccessToken/' + userId + '/' + roomSid;
            

            // Fetch an access token from the server
            const response = await fetch(url_join, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                     'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    identity,
                    roomSid
                }),
            });

            const data = await response.json();
            // console.log("data", data)
            // console.log("OK")
            let responseData = JSON.parse(data.responseData);
            let accessToken = responseData.accessToken

            // console.log("accessToken", accessToken)

            let videoRoom = await connectToRoom(accessToken) // Connect to the video room
            setRoom(videoRoom); // Save this video room in the state
            if (data.status == 200) {
            } else {
                console.log("ERROR")
            }


        } catch (err) {
            console.log(err);
        }
    };




    // acá en vez de mandar a next_meetings, mandar a historial de meetings pasando como prop el id de la reunion para abrir el modal
    // Leave a video room
    const leaveRoom = async () => {
        if (room) {
            // Detach and remove all the tracks
            room.localParticipant.tracks.forEach(publication => {
                if (publication.track.kind === 'audio' || publication.track.kind === 'video') {
                    publication.track.stop();
                    const attachedElements = publication.track.detach();
                    attachedElements.forEach(element => element.remove());
                }
            });

            room.disconnect();
            setRoom(undefined);
            // console.log("Saliendo")
            // navigate('/meetings/next_meetings');
            navigate('/meetings/next_meetings', { state: { meetingIdConfirm: data_meeting.id} });
            // navigate('/meetings/historial', { state: { meetingId: data_meeting.id } });
        }
    };

    function handleJoinMeeting(userId: number, roomName: string) {
        var roomSid = roomName
        var room = joinRoom(userId, roomName)
    }

    function handleCreateMeeting(userId: number, roomName: string) {
        var roomSid = roomName
        var room = createRoom(userId, roomName)
    }

    function handleCreateAndJoinMeeting(userId: number, roomName: string) {
        try {
            handleCreateMeeting(userId, roomName)
        } catch {

        }
        try {
            handleJoinMeeting(userId, roomName)
        } catch {

        }
    }
    let logoEtm = "/logo-etm.png"
    let logoEtm_chico = "/logo-chico-etm-blanco.png"
    let go_to_home = "/home"
    let navbar_html = < div className="navbar fixed-top padding-layout-x  " >
        <div className='d-flex justify-content-between justify-content-between align-items-center w-100'>
            <div >
                <div className='d-flex justify-content-start align-items-center'>

                    <div className="logo-principal-container d-block d-md-none">
                        <img className='logo-principal' src={process.env.PUBLIC_URL + logoEtm_chico} alt="ETM" />
                    </div>
                    <div className="logo-principal-container d-none d-md-block mr-4">
                        <img className='logo-principal' src={process.env.PUBLIC_URL + logoEtm} alt="ETM" />
                    </div>
                </div>
            </div>
            <div className=''>
                <Timer
                    UserRequestedEndTime={UserRequestedEndTime}
                    UserRequestedStartTime={UserRequestedStartTime}
                    leave={leaveRoom}
                    setExtraTime={setExtraTime}
                ></Timer>
            </div>
        </div>
    </div >
    return (
        <div className='twillio '>
            {navbar_html}
            {extraTime ?
                <div className='room-time-warning padding-layout-x'>
                    <div className='room-time-warning-content'>

                        <p className='text-end'>{textVideollamada.titulo_1}</p>
                    </div>
                </div>

                : <></>}
            {
                room !== undefined ?
                    <Room
                        room={room}
                        setRoom={setRoom}
                        leaveRoom={leaveRoom}
                        parentSid={parentSid}
                        UserRequestedEndTime={UserRequestedEndTime}
                        UserRequestedStartTime={UserRequestedStartTime}
                        UserRequestedProfilePicture={UserRequestedProfilePicture}
                        UserRequestProfilePicture={UserRequestProfilePicture}
                        UserRequestName={UserRequestName}
                        UserRequestLastName={UserRequestLastName}
                        UserRequestedName={UserRequestedName}
                        UserRequestedLastName={UserRequestedLastName}
                        UserRequestId={UserRequestId}
                        UserRequestedId={UserRequestedId}
                    /> : <></> // activeVideo={activeVideo} activeAudio={activeAudio}
            }
        </div>
    );
};

export default VideoCall2;