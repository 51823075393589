import React, { useEffect, useState } from 'react';
import { connect, Room as VideoRoom } from 'twilio-video';
import io, { Socket } from 'socket.io-client';
import Room from './Room';
import "./twillio.scss"
import Timer from '../Timer/Timer';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../../src/redux/features/language/LanguageSlice';

export interface MainRoom {
    _id: string;
    name: string;
    breakouts: BreakoutRoom[]
}

export interface BreakoutRoom {
    _id: string;
    name: string;
}

const VideoCall = () => {
    // State variables
    
    const textVideollamada= useSelector(selectCurrentDictonary).videollamada
    const [identity, setIdentity] = useState('userid');
    const [room, setRoom] = useState<VideoRoom>();
    const [roomList, setRoomList] = useState<MainRoom[]>([]);
    const [showControls, setShowControls] = useState(false);
    const [newRoomName, setNewRoomName] = useState('');
    const [RoomName, setRoomName] = useState('');
    const [ActualUserId, setActualUserId] = useState(0);
    const [parentSid, setParentSid] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    
    const [activeAudio, setActiveAudio] = useState(true);
    const [activeVideo, setActiveVideo] = useState(true);

    useEffect(() => {

    }, []);



    // Create a new main room
    const createRoom = async (userId: number, roomName: string) => {

        const token = JSON.parse(localStorage.getItem('sessionA')!)?.id || '';

        try {
            let url_create2 = 'https://localhost:61148/api/Meetings/AddTwillioRoom/'+userId+'/'+roomName
            //let url_create = 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod/api/Meetings/AddTwillioRoom/'+userId+'/'+roomName
            let url_create = 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod/api/Meetings/AddTwillioRoom/'+userId+'/'+roomName
            const response = await fetch(url_create, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            await response.json();


        } catch (err) {
            console.log(err);
        }
    };


    const connectToRoom = async (accessToken:string) => {

        

        try {
            const videoRoom = await connect(accessToken, {
                audio: true,
                video: { width: 640, height: 480 }
            });
            return videoRoom;
            
        } catch (error) {
            
            const videoRoom = await connect(accessToken, {
                audio: true,
                //video: { width: 640, height: 480 }
            });
            return videoRoom;
        }
    }
    // Join a video room
    const joinRoom = async (userId: number, roomSid: string, breakout: boolean = false) => {

        const token = JSON.parse(localStorage.getItem('sessionA')!)?.id || '';

        try {
            // If you're already in another video room, disconnect from that room first
            if (room) {
                await room.disconnect();
            }

            let url_join2 = 'https://localhost:61148/api/Meetings/GetTwiliioAccessToken/'+userId+'/'+roomSid
            //let url_join = 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod/api/Meetings/GetTwiliioAccessToken/'+userId+'/'+roomSid
            let url_join = 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod/api/Meetings/GetTwiliioAccessToken/'+userId+'/'+roomSid
            
            // Fetch an access token from the server
            const response = await fetch(url_join, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    identity,
                    roomSid
                }),
            });

            const data = await response.json();
            // console.log("data", data)
            let responseData = JSON.parse(data.responseData);
            let accessToken = responseData.accessToken
            
            // console.log("accessToken", accessToken)

            let videoRoom = await connectToRoom(accessToken) // Connect to the video room
            setRoom(videoRoom); // Save this video room in the state

        } catch (err) {
            console.log(err);
        }
    };


    // Join a video room
    const createAndjoinRoom = async (userId: number, roomName: string) => {

        const token = JSON.parse(localStorage.getItem('sessionA')!)?.id || '';


        try {
            // If you're already in another video room, disconnect from that room first
            if (room) {
                await room.disconnect();
            }

            //let url_create = 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod/api/Meetings/CreateAndJoinTwillioRoom/'+userId+'/'+roomName
            let url_create = 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod/api/Meetings/CreateAndJoinTwillioRoom/'+userId+'/'+roomName
            
            // Fetch an access token from the server
            const response = await fetch(url_create, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const data = await response.json();
            // console.log("data", data)
            let responseData = JSON.parse(data.responseData);
            let accessToken = responseData.accessToken
            // Connect to the video room
            // console.log("accessToken", accessToken)
            
            let videoRoom = await connectToRoom(accessToken) // Connect to the video room
            setRoom(videoRoom); // Save this video room in the state


        } catch (err) {
            console.log(err);
        }
    };




    // Leave a video room
    const leaveRoom = async () => {
        if (room) {
            // Detach and remove all the tracks
            room.localParticipant.tracks.forEach(publication => {
                if (publication.track.kind === 'audio' || publication.track.kind === 'video') {
                    publication.track.stop();
                    const attachedElements = publication.track.detach();
                    attachedElements.forEach(element => element.remove());
                }
            });

            room.disconnect();
            setRoom(undefined);
        }
    };

    function handleJoinMeeting(userId: number, roomName: string) {
        var roomSid = RoomName
        var room = joinRoom(userId, roomName)
    }

    function handleCreateMeeting(userId: number, roomName: string) {
        var roomSid = RoomName
        var room = createRoom(userId, roomName)
    }

    function handleCreateAndJoinMeeting(userId: number, roomName: string){
        try {
            handleCreateMeeting(userId, roomName)
        }catch{

        }
        try {
            handleJoinMeeting(userId, roomName)
        }catch{

        }
    }

    return (
        <div className='twillio body-container'>
            <div className='row'>
                {/* <div className='col-12 mb-2'>
                    <h6><b>{"connectToRoom"}</b></h6>
                    <button className='btn btn-primary' onClick={() => createAndjoinRoom(ActualUserId ,RoomName)}>connectToRoom</button>
                </div> */}
                {/* <div className='col-12'>
                    <Timer
                        UserRequestedEndTime={UserRequestedEndTime}
                        UserRequestedStartTime={UserRequestedStartTime}
                        leave={leaveRoom}
                    ></Timer>
                </div> */}
                <div className='col-12 mb-5'>
                    {
                        room !== undefined
                        ? <Room 
                            setRoom={setRoom}
                            room={room} 
                            leaveRoom={leaveRoom} 
                            parentSid={parentSid}  
                        /> : <></> // activeVideo={activeVideo} activeAudio={activeAudio}
                    }
                </div>
                <div className='col-12 mb-5'>
                    <h6><b>{"Create and join Meeting"}</b></h6>
                    <h6><b>{"identity: " + identity}</b></h6>
                    <h6><b>{"RoomName"}</b></h6>
                    <h6>{RoomName}</h6>
                    <input
                        value={RoomName}
                        onChange={(event) => {
                            setRoomName(event.target.value)
                        }} />
                    <h6><b>{"ActualUserId"}</b></h6>
                    <h6>{ActualUserId}</h6>
                    <input
                        value={ActualUserId}
                        onChange={(event) => {
                            setActualUserId(parseInt(event.target.value))
                        }} />
                    <button className='btn btn-primary' onClick={() => handleCreateAndJoinMeeting(ActualUserId ,RoomName)}>{textVideollamada.titulo_6}</button>

                </div>
                <div className='col-6 mb-5'>
                    <h6><b>{"Create Meeting"}</b></h6>
                    <h6><b>{"identity: " + identity}</b></h6>
                    <h6><b>{"RoomName"}</b></h6>
                    <h6>{RoomName}</h6>
                    <input
                        value={RoomName}
                        onChange={(event) => {
                            setRoomName(event.target.value)
                        }} />
                    <h6><b>{"ActualUserId"}</b></h6>
                    <h6>{ActualUserId}</h6>
                    <input
                        value={ActualUserId}
                        onChange={(event) => {
                            setActualUserId(parseInt(event.target.value))
                        }} />
                    <button className='btn btn-primary' onClick={() => handleCreateMeeting(ActualUserId ,RoomName)}>{textVideollamada.titulo_7}</button>

                </div>

                <div className='col-6 mb-5'>
                    <h6><b>{"Join Meeting"}</b></h6>
                    <h6><b>{"identity: " + identity}</b></h6>
                    <h6><b>{"RoomName"}</b></h6>
                    <h6>{RoomName}</h6>
                    <input
                        value={RoomName}
                        onChange={(event) => {
                            setRoomName(event.target.value)
                        }} />
                    <h6><b>{"ActualUserId"}</b></h6>
                    <h6>{ActualUserId}</h6>
                    <input
                        value={ActualUserId}
                        onChange={(event) => {
                            setActualUserId(parseInt(event.target.value))
                        }} />
                    <button className='btn btn-primary' onClick={() => handleJoinMeeting(ActualUserId ,RoomName)}>{textVideollamada.titulo_8}</button>

                </div>
            </div>
        </div>
    );
};

export default VideoCall;