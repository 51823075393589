import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    user: null,
    token: null,
    refresh_token: null,
    user_id: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { id, refresh, user_id } = action.payload
            state.user_id = user_id
            state.refresh_token = refresh
            state.token = id
            console.log("sesion iniciada, token a cargar", id);

            localStorage.setItem(
                "sessionA",
                JSON.stringify({
                  id: id,
                  refresh: refresh,
                  user_id,
                })
              )
        },
        setAuthToken: (state, action) => {
            const { token } = action.payload
            state.token = token
        },
        setUserData: (state, action) => {
            const { user } = action.payload
            state.user = user
        },
        logOut: (state, action) => {
            state.user = null
            state.token = null
            localStorage.removeItem("session")
            localStorage.removeItem("sessionA")
        },
    },
})

export const { setCredentials, setAuthToken, setUserData, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: any) => state.auth.user
export const selectCurrentToken = (state: any) => state.auth.token
