import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
 

const baseQuery = fetchBaseQuery({
    //baseUrl: 'https://localhost:61148',
     //baseUrl: 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/TestCognitoAuth',
     baseUrl: 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod',
});

export const refreshToken = async (refreshToken: string, api: any): Promise<any> =>  {
    const response = await baseQuery(
        {
            url: '/Api/Auth/Refresh',
            method: 'POST',
            body: { RefreshToken: refreshToken },
        },
        api,  
        {}  
    );

    if (response.error) {
        console.log("1")
        console.log(response)
        console.log(response)
        //window.location.href = '/sign-out1'
        throw new Error('Error al renovar el token');
       
        
    }

    return response.data;
};
