import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useGetUserPersonalDataMutation, useGetUserPublicDataMutation } from '../../redux/features/user/userApiSlice'
import { useGetRolesMutation, useGetSponsorsMutation } from '../../redux/features/dimensions/dimensionsApiSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { setPersonalData, setUserPublicData } from '../../redux/features/user/userSlice'
import { setRoles, setSponsors } from '../../redux/features/dimensions/dimensionsSlice'
import { desencriptarToJSON } from '../../utils/encrypt'
import { personalData } from '../../interfaces/dimentions'
import { LoadingPage } from '../LoadingPage/LoadingPage'
import { useRefreshTokenMutation } from '../../redux/features/auth/authApiSlice'
import { setCredentials } from '../../redux/features/auth/authSlice'


export const CheckSession = ({ children }: any) => {
  const [checking, setChecking] = useState(true)
  const dispatch = useDispatch()
  const [getUserPersonalData, { isLoadingGetUserPersonalData }]: any = useGetUserPersonalDataMutation()
  const [getRoles, { isLoadingGetRoles }]: any = useGetRolesMutation()
  const [getSponsors, { isLoadingGetSponsors }]: any = useGetSponsorsMutation()
  const [getUserPublicData]: any = useGetUserPublicDataMutation()
  const [refreshToken] = useRefreshTokenMutation();

  const location = useLocation();
  const navigate = useNavigate()

  const setSession = async (userId: string) => {

    //peticion de datos personales del usuario                
    const res_getUserPersonalData: any = await getUserPersonalData({ user_id: userId })
    const personal_data_aux: personalData = JSON.parse(res_getUserPersonalData.data.responseData)
    dispatch(setPersonalData({ personal_data: personal_data_aux }))

    // peticion de datos publicos
    const res: any = await getUserPublicData({ user_id: userId });
    const resdata = JSON.parse(res.data.responseData)
    dispatch(setUserPublicData({ user_public_data: resdata }))

    if (!localStorage.getItem("userCache")) {
      //traer listado de roles (dimensions)
      const res_getRoles: any = await getRoles()
      const roles_aux = JSON.parse(res_getRoles.data.responseData)
      dispatch(setRoles({ roles: roles_aux }))

      //traer listado de sponsors (dimensions)
      const res_getSponsors: any = await getSponsors()
      const sponsors_aux = JSON.parse(res_getSponsors.data.responseData)
      dispatch(setSponsors({ sponsors: sponsors_aux }))

      const userCache = {
        roles: roles_aux,
        sponsors: sponsors_aux
      }

      localStorage.setItem("userCache", JSON.stringify(userCache))

    }
    else {
      const cachePublicInfo = JSON.parse(localStorage.getItem("userCache")!)
      dispatch(setRoles({ roles: cachePublicInfo.roles }))
      dispatch(setSponsors({ sponsors: cachePublicInfo.sponsors }))
    }

  }
  const handleTokenRefresh = async () => {
    const storedToken = JSON.parse(localStorage.getItem('sessionA')!);
    console.log("-------------------------");
  
    try {
      
      const response = await refreshToken({ refresh_token: storedToken.refresh }).unwrap();
      
      console.log("REEES", response);  
  
     
      const parsedData = JSON.parse(response.responseData);
  
      console.log("TOKEN", parsedData.IdToken);
      console.log("REFRESH TOKEN", parsedData.RefreshToken);
  
       
      localStorage.setItem(
        'sessionA',
        JSON.stringify({
          id: parsedData.IdToken,
          refresh: parsedData.RefreshToken,
          user_id: storedToken.user_id,
        })
      );
      dispatch(setCredentials({
        id: parsedData.IdToken,
        refresh: parsedData.RefreshToken,
        user_id: storedToken.user_id,
      }));
  
      console.log('Tokens renovados exitosamente  ');
    } catch (error) {
      console.error('Error al renovar tokens:', error);
      // localStorage.removeItem('sessionA');
      // alert("4")
      // navigate('/sign-out');
    }
  };
  

  useEffect(() => {

    async function getSession(info: string) {

      const session = JSON.parse(desencriptarToJSON(info));

      if (session?.isLoggedIn && session?.userId) {
        await handleTokenRefresh();
        await setSession(session.userId)

        if (location.pathname) {
          // No redirigir si la ubicación actual es '/user-profile/'
          setChecking(false);
        }/* else if (state && state.from && state.from.pathname && state.from.pathname !== '/sign-in') {
              navigate(state.from.pathname) 
          } */else {
          navigate(location.pathname)
        }
      } else {
        setChecking(false);
      }
    }
    const hashValue = localStorage.getItem("session");
    if (hashValue) {
      getSession(hashValue)
    }
    else {
      setChecking(false)
    }
   
   

   

  }, [])

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      const hashValue = localStorage.getItem('session');
  
      if (!hashValue) {
       
        console.log("SESION CERRADA,No se actualizará más el token")
        clearInterval(refreshInterval);
      } else {
       
        handleTokenRefresh();
      }
    }, 600000);
  
    
    return () => clearInterval(refreshInterval);
  }, []);
  
 
 

  if (checking) {
    return (<LoadingPage />)
  }

  return children

}
